<template>
  <Navigation/>
  <router-view/>
</template>

<script>

import { useRoute, useRouter } from 'vue-router';
import Navigation from "./components/NavigationView.vue"

export default{
  setup(){
    const router = useRouter();
    const route = useRoute();
    console.log("Ruta" + route.path)
    console.log("Ruta" + router.path)
  },
  components:{
    Navigation,
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

/* The navigation bar */
.navbar {
  overflow: hidden;
  background-color: #333;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
}

/* Links inside the navbar */
.navbar a {
  float:inline-end;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
}

/* Change background on mouse-over */
.navbar a:hover {
  background: #ddd;
  color: black;
}

/* Main content */
.main {
  margin-top: 60px; /* Add a top margin to avoid content overlay */
}



</style>
