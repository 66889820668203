import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueSocialChat from 'vue-social-chat'
import 'vue-social-chat/dist/style.css'
//import store from './store'

createApp(App)
    .use(router)
    .mount('#app')
    .use(VueSocialChat)
