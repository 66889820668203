<template>
    <header :class="{'scrolled-nav': scrollNav}">
        
        <nav>
            <div class="branding">
                <img src="@/assets/Automatizate.png" alt=""/>
                
                
                <b style="font-size:25px;color: navy;margin-left: 8px;">Automatizate 360</b>
                
            </div>
        
            <ul v-show="!mobile" class="navigation">
                <li><router-link class="link" :to="{name: 'InicioView'}">Inicio</router-link></li>
                <li><router-link class="link" :to="{name: 'ServiciosView'}">Servicios</router-link></li>
                <li><router-link class="link" :to="{name: 'NosotrosView'}">Nosotros</router-link></li>
                <li><router-link class="link" :to="{name: ''}">Contactenos</router-link></li>
            </ul>
            <div class="icon">
                <!--
                <i @click="toggleMobileNav" v-show="mobile" class="fa-regular fa-bars" :class="{'icon-active':mobileNav}"></i>
                 -->
                
                <img @click="toggleMobileNav"  v-show="mobile" style="width: 50px;height: 50px;margin-top: 10px;" class="icon" :class="{'icon-active':mobileNav}" src="@/assets/Iconos/bars-solid.png"/>
                   
            </div>
            <transition name="mobile-nav">
                <ul v-show="mobileNav" class="dropdown-nav">
                <li><router-link class="link" :to="{name: 'InicioView'}">Inicio</router-link></li>
                <li><router-link class="link" :to="{name: 'ServiciosView'}">Servicios</router-link></li>
                <li><router-link class="link" :to="{name: 'NosotrosView'}">Nosotros</router-link></li>
                <li><router-link class="link" :to="{name: ''}"> Contactenos</router-link></li>
                </ul>
            </transition>
        </nav>
        </header>
</template>

<script>

/* eslint-disable */
export default {
    name: "navigation",

    data(){
        return{
            scrollNav:null,
            mobile:true,
            mobileNav:null,
            windowwidth:null,
        }
    },
    setup () {
        
 
        return {}
    },
    created(){
        window.addEventListener('resize',this.checkScreen)
        this.checkScreen();
    },
    mounted() {
        window.addEventListener("scroll",this.updateScroll)
    },
    methods: {
        toggleMobileNav(){
            this.mobileNav = !this.mobileNav;
            console.log('mobileNav:' + this.mobileNav)
        },
        updateScroll(){
            const scrollPosition = window.scrollY;
            if(scrollPosition>50){
                this.scrollNav =true;
                return;
            }
            this.scrollNav = false
        },
        checkScreen(){
            this.windowwidth = window.innerWidth;
            if(this.windowwidth<= 750){
                this.mobile = true;
                //console.log('mobile:' + this.mobile)
                return;
            }
            this.mobile = false;
            this.mobileNav=false;
            //console.log('mobile:' + this.mobile)
            console.log('mobileNav:' + this.mobileNav)
            return;
        }
    },
}
</script>

<style lang="scss" scoped>
    header{
        background-color: rgba(201,226,101,0.8);
        z-index: 99;
        width: 100%;
        position: fixed;
        transition: 0.5s ease all;
        color: #fff;
    
        nav{
            position: relative;
            display: flex;
            flex-direction: row;
            padding: 12px 0;
            transition: 0.5s ease all;
            width: 90%;
            margin: 0 auto;
            @media (min-width: 1140px){
                max-width: 1140px;
            }
        }

        ul,
        .link{
            font-weight: 500;
            color: navy;
            list-style: none;
            text-decoration: none;
        }

        li {
            text-transform: uppercase;
            padding: 16px;
            margin-left: 16px;
        }
        .link{
            font-size: 14px;
            transition: .5s ease all;
            padding-bottom: 4px;
            border-bottom: 1px solid transparent;

            &:hover {
                color: #00afea;
                border-color: #00afea;
            }
        }
        .branding{
            display: flex;
            align-items: center;

            img{
                width: 70px;
                transition: .5s ease all;
            }
        }

        .navigation{
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: flex-end;
        }
        .icon{
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            right: 24px;
            height: 100%;

            i{
                cursor: pointer;
                font-size: 24px;
                transition: 0.8s ease all;
            }
        }

        .icon-active {
            transform: rotate(180deg);
        }

        .dropdown-nav{
            display: flex;
            flex-direction: column;
            position: fixed;
            width: 100%;
            max-width: 250px;
            height: 100%;
            background-color:#FAFAFA;
            top: 0;
            left: 0;

            li{
                margin-left: 0;
                .link{
                    color: navy;
                }
            }
        }

        .mobile-nav-enter-active,
        .mobile-nav-leave-active{
            transition: 1s ease all;
        }
        
        .mobile-nav-enter-from,
        .mobile-nav-leave-to{
            transform: translateX(-250px);
        }

        .mobile-nav-enter-to{
            transform: translateX(0);
        }
    }
    .scrolled-nav{
        background-color: #c9e265;
        box-shadow: 0 4px 6px -1px rgba(201,226,101,0.1),0 2px 4px -1px rgba(201,226,101,0.06);
    
        nav{
            padding: 8px 0;
            .branding{
                img{
                    width: 40px;
                    box-shadow: 0 4px 6px -1px rgba(201,226,101,0.1),0 2px 4px -1px rgba(201,226,101,0.06);
                }
            }
        }
    }
    .icono{
    height: 25px;
}
</style>